import React, {useEffect} from 'react';
import './Rosters.css';

const unallocated = {
    "name": "Unallocated",
    "staff": [""],
    "vehicle": "",
    "duties": [
        {
            "day": "Monday",
            "time": "07:00 - 09:00",
            "staff": "CH01",
            "type": "unallocated",
            "id": 1
        },
        {
            "day": "Monday",
            "time": "07:00 - 09:00",
            "staff": "CH02",
            "type": "unallocated",
            "id": 2
        },
        {
            "day": "Tuesday",
            "time": "07:00 - 09:00",
            "staff": "CH04",
            "type": "unallocated",
            "id": 3
        },
        {
            "day": "Tuesday",
            "time": "07:00 - 09:00",
            "staff": "CH05",
            "type": "unallocated",
            "id": 4
        },
        {
            "day": "Wednesday",
            "time": "09:20 - 11:00",
            "staff": "CH06",
            "type": "unallocated",
            "id": 5
        },
        {
            "day": "Saturday",
            "time": "09:20 - 11:00",
            "staff": "CH06",
            "type": "unallocated",
            "key": 0,
            "id": 6
        },
        {
            "day": "Sunday",
            "time": "09:20 - 11:00",
            "staff": "CH06",
            "type": "unallocated",
            "key": 0,
            "id": 7
        }
    ]
};

const initialRosters = [
    {
        "id": 0,
        "name": "Roster 1",
        "staff": ["Jane Smith"],
        "vehicle": "MO1111",
        "duties": [
            {
                "day": "Monday",
                "time": "07:00 - 09:00",
                "staff": "JS",
                "type": "shiftbat",
                "id": 8
            },
            {
                "day": "Monday",
                "time": "15:00 - 17:00",
                "staff": "JS",
                "type": "charter",
                "id": 9
            },
            {
                "day": "Tuesday",
                "time": "07:00 - 09:00",
                "staff": "JS",
                "type": "shiftbat",
                "id": 10
            },
            {
                "day": "Tuesday",
                "time": "15:00 - 17:00",
                "staff": "JS",
                "type": "charter",
                "id": 11
            },
            {
                "day": "Wednesday",
                "time": "07:00 - 09:00",
                "staff": "JS",
                "type": "shiftbat",
                "id": 12
            },
            {
                "day": "Wednesday",
                "time": "15:00 - 17:00",
                "staff": "JS",
                "type": "charter",
                "id": 13
            },
            {
                "day": "Thursday",
                "time": "07:00 - 09:00",
                "staff": "JS",
                "type": "shiftbat",
                "id": 14
            },
            {
                "day": "Thursday",
                "time": "15:00 - 17:00",
                "staff": "JS",
                "type": "charter",
                "id": 15
            },
            {
                "day": "Friday",
                "time": "07:00 - 09:00",
                "staff": "JS",
                "type": "shiftbat",
                "id": 16
            },
            {
                "day": "Friday",
                "time": "15:00 - 17:00",
                "staff": "JS",
                "type": "charter",
                "id": 17
            }
        ]
    },
    {
        "id": 1,
        "name": "Roster 2",
        "staff": ["Peter Pan", "Paul Jones"],
        "vehicle": "MO2222",
        "duties": [
            {
                "day": "Monday",
                "time": "07:00 - 09:00",
                "staff": "PP",
                "type": "shiftbat",
                "id": 18
            },
            {
                "day": "Monday",
                "time": "15:00 - 17:00",
                "staff": "PP",
                "type": "charter",
                "id": 19
            },
            {
                "day": "Tuesday",
                "time": "07:00 - 09:00",
                "staff": "PP",
                "type": "shiftbat",
                "id": 20
            },
            {
                "day": "Tuesday",
                "time": "15:00 - 17:00",
                "staff": "PP",
                "type": "charter",
                "id": 21
            },
            {
                "day": "Wednesday",
                "time": "07:00 - 09:00",
                "staff": "PP",
                "type": "shiftbat",
                "id": 22
            },
            {
                "day": "Wednesday",
                "time": "15:00 - 17:00",
                "staff": "PP",
                "type": "charter",
                "id": 23
            },
            {
                "day": "Thursday",
                "time": "07:00 - 09:00",
                "staff": "PP",
                "type": "shiftbat",
                "id": 24
            },
            {
                "day": "Thursday",
                "time": "15:00 - 17:00",
                "staff": "PP",
                "type": "charter",
                "id": 25
            },
            {
                "day": "Friday",
                "time": "07:00 - 09:00",
                "staff": "PP",
                "type": "shiftbat",
                "id": 26
            },
            {
                "day": "Friday",
                "time": "15:00 - 17:00",
                "staff": "PP",
                "type": "charter",
                "id": 27
            },
            {
                "day": "Tuesday",
                "time": "09:20 - 11:00",
                "staff": "PJ",
                "type": "shiftbat",
                "id": 28
            },
            {
                "day": "Tuesday",
                "time": "15:00 - 17:00",
                "staff": "PJ",
                "type": "charter",
                "id": 29
            }
        ]
    },
    {
        "id": 2,
        "name": "Roster 3",
        "staff": ["Unassigned"],
        "vehicle": "MO1234",
        "duties": [
            {
                "day": "Monday",
                "time": "07:00 - 09:00",
                "staff": "N/A",
                "type": "shiftbat",
                "id": 30
            },
            {
                "day": "Monday",
                "time": "15:00 - 17:00",
                "staff": "N/A",
                "type": "charter",
                "id": 31
            },
            {
                "day": "Tuesday",
                "time": "07:00 - 09:00",
                "staff": "N/A",
                "type": "shiftbat",
                "id": 32
            },
            {
                "day": "Tuesday",
                "time": "15:00 - 17:00",
                "staff": "N/A",
                "type": "charter",
                "id": 33
            },
            {
                "day": "Wednesday",
                "time": "07:00 - 09:00",
                "staff": "N/A",
                "type": "shiftbat",
                "id": 34
            },
            {
                "day": "Wednesday",
                "time": "15:00 - 17:00",
                "staff": "N/A",
                "type": "charter",
                "id": 35
            },
            {
                "day": "Thursday",
                "time": "07:00 - 09:00",
                "staff": "N/A",
                "type": "shiftbat",
                "id": 36
            },
            {
                "day": "Thursday",
                "time": "15:00 - 17:00",
                "staff": "N/A",
                "type": "charter",
                "id": 37
            },
            {
                "day": "Friday",
                "time": "07:00 - 09:00",
                "staff": "N/A",
                "type": "shiftbat",
                "id": 38
            },
            {
                "day": "Friday",
                "time": "15:00 - 17:00",
                "staff": "N/A",
                "type": "charter",
                "id": 39
            }
        ]
    }
];

const HEADERS = ["Roster", "Staff", "Vehicle", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const WeeklyRosters = ({
                           isLoading,
                           setLoading,
                           startDate,
                           endDate,
                           showUnallocated,
                           setUnAllocatedCount
                       }) => {

    const [rosters, setRosters] = React.useState(initialRosters);
    const [unallocatedRosters, setUnallocatedRosters] = React.useState(unallocated);
    const [selectedRoster, setSelectedRoster] = React.useState(null);
    const [selectedUnallocated, setSelectedUnallocated] = React.useState(null);


    useEffect(() => {
        if (unallocatedRosters) {
            setLoading(false);
            setUnAllocatedCount(unallocatedRosters.duties.length)
        }
    }, [unallocatedRosters]);


    const onUnAllocatedItemSelect = (duty) => {
        const isSameItemSelected = duty && selectedUnallocated.key === duty.key;
        if (!isSameItemSelected) {
            const newRosters = rosters.map((roster, index) => {
                return {
                    ...roster,
                    shifts: [...roster.shifts, duty]
                }
            })
            setRosters(newRosters)
            setSelectedUnallocated(duty)
        } else {
            setRosters(initialRosters)
            setSelectedUnallocated(null)
        }
    }

    const onRosterItemSelect = (duty) => {
        const isSameItemSelected = duty && selectedRoster.key === duty.key;
        setSelectedRoster(isSameItemSelected ? null : duty)
    }

    return (
        <div style={{flexGrow: 1}}>
            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr repeat(7, 2fr)", padding: "10px"}}>
                {/*<div>Roster</div>*/}
                {/*<div>Staff</div>*/}
                {/*<div>Vehicle</div>*/}
                {HEADERS.map((value) => (
                    <div key={value} className='roster-header'>
                        {value}
                    </div>
                ))}
            </div>
            {showUnallocated && unallocatedRosters &&
                <UnAllocatedRosterRow unallocatedRosters={unallocatedRosters}
                                      onUnAllocatedItemSelect={onUnAllocatedItemSelect}/>
            }
            {rosters.map((roster) => (
                <RosterRow roster={roster}
                           onRosterItemSelect={onRosterItemSelect}/>
            ))}
        </div>
    );
};

const RosterRow = ({roster, onRosterItemSelect}) => {
    return (
        <div key={roster.id}
             style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr repeat(7, 2fr)", padding: "10px"}}>
            <div>{roster.name}</div>
            <div>
                {roster.staff && roster.staff.map((staffName, index) => (
                    <div key={index}>
                        <div class="intials">JJ</div>
                        <div>{staffName}</div>
                    </div>
                ))}
            </div>
            <div>{roster.vehicle}</div>
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                <RosterItems day={day} roster={roster} onRosterItemSelect={onRosterItemSelect}/>
            ))}
        </div>
    )
}

const RosterItems = ({day, roster, onRosterItemSelect}) => {
    return (
        <div key={day} className="roster-cell">
            {roster.duties
                .filter((duty) => duty.day === day)
                .map((duty, idx) => (
                    <div key={idx} className={`roster-item shift ${duty.type}`} onClick={() => {
                        onRosterItemSelect(duty)
                    }}>
                        <div className="staff-name">{duty.staff}</div>
                        <div className="staff-name">{duty.vehicle}</div>
                        <div className="time-slot">{duty.time}</div>
                    </div>
                ))}
        </div>
    )
};

const UnAllocatedRosterRow = ({unallocatedRosters, onUnAllocatedItemSelect}) => {
    return (
        <div
             style={{display: "grid", gridTemplateColumns: "3fr repeat(7, 2fr)", padding: "10px"}}>
            <div>Unallocated Rosters</div>
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                <div key={day} className="roster-cell">
                    {unallocatedRosters.duties
                        .filter((duties) => duties.day === day)
                        .map((duty, idx) => (
                            <div key={idx} className={`unallocated-duty-item`} onClick={() => {
                                onUnAllocatedItemSelect(duty)
                                // alert(JSON.stringify(shift));
                            }}>
                                <div className="staff-name">{duty.staff}</div>
                                <div className="time-slot">{duty.time}</div>
                            </div>
                        ))}
                </div>
            ))}
        </div>
    )
}

export default React.memo(WeeklyRosters);