import React, {useCallback} from 'react';
import './Charters.css';
import {Card, Col, Menu, Tooltip} from 'antd';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {useAppContext} from '../../libs/contextLib';
import {ReactComponent as Calender} from '../../assets/icons/Calendar.svg';
import {ReactComponent as Bus} from '../../assets/icons/Bus.svg';
import {ReactComponent as Rostering} from '../../assets/icons/Rostering.svg';
import {ReactComponent as Quote} from '../../assets/icons/Quote.svg';
import {ReactComponent as Warning} from '../../assets/icons/Warning.svg';

function getItem({label, key, icon, children, type, disabled}) {
    return {
        key,
        icon,
        children,
        label,
        type,
        disabled,
    };
}

function CharterMenu({
                         activeKey,
                         charter,
                         disabled = {charter: false, itinerary: false, duty: false, quote: false},
                         hide = {charter: false, itinerary: false, duty: false, quote: false},
                         warning = {charter: null, itinerary: null, duty: null, quote: null},
                     }) {
    const history = useHistory();
    const {setBreadcrumb} = useAppContext();
    setBreadcrumb(charter?.name || 'New Charter');

    const items = useCallback(() => {
        if (!charter || !hide || !disabled) {
            return [];
        }
        const items = [];
        if (!hide.charter) {
            items.push(
                getItem({
                    label: <span>Customer Details{warning.charter ? <Tooltip title={warning.charter}><Warning
                        className="header-icon w-16"/></Tooltip> : <></>}</span>,
                    key: '1',
                    icon: <Calender width={20} height={20}/>,
                    disabled: disabled.charter,
                })
            );
        }
        if (!hide.itinerary) {
            items.push(
                getItem({
                    label: <span>Customer Itinerary{warning.itinerary ? <Tooltip title={warning.itinerary}><Warning
                        className="header-icon w-16"/></Tooltip> : <></>}</span>,
                    key: '2',
                    icon: <Bus width={20} height={20}/>,
                    disabled: disabled.itinerary,
                })
            );
        }

        if (!hide.duty) {
            items.push(
                getItem({
                    label: <span>Driver Duty{warning.duty ? <Tooltip title={warning.duty}><Warning
                        className="header-icon w-16"/></Tooltip> : <></>}</span>,
                    key: '3',
                    icon: <Rostering width={20} height={20}/>,
                    disabled: disabled.duty,
                })
            );
        }

        if (!hide.quote) {
            items.push(
                getItem({
                    label: <span>Quote{warning.quote ? <Tooltip title={warning.quote}><Warning
                        className="header-icon w-16"/></Tooltip> : <></>}</span>,
                    key: '4',
                    icon: <Quote width={20} height={20}/>,
                    disabled: disabled.quote,
                })
            );
        }

        return items;
    }, [charter, disabled, hide]);

    const onMenuSelect = useCallback(
        ({key}) => {
            const menu = items().find((item) => item.key === key).key;

            switch (menu) {
                case '1':
                    history.push(`/charters/${charter.charterId}`);
                    break;
                case '2':
                    history.push(`/charters/${charter.charterId}/itinerary`);
                    break;
                case '3':
                    history.push(`/charters/${charter.charterId}/duty`);
                    break;
                case '4':
                    history.push(`/charters/${charter.charterId}/quote`);
                    break;
                default:
                    break;
            }
        },
        [history, charter, items]
    );

    return (
        <Col xs={24} lg={24} className="no-print">
            <Card bordered={false} className="card-main body-pad-0 CardMenu">
                {/* <div className="profile-summary">
                    <h2>{charter?.name || "New Charter"}</h2>
                    <div className="text-secondary">{charter?.date}</div>
                </div> */}
                <Menu
                    className="profile-menu"
                    mode="inline"
                    theme="light"
                    items={items()}
                    selectedKeys={[activeKey]}
                    onSelect={onMenuSelect}
                />
            </Card>
        </Col>
    );
}

export default React.memo(CharterMenu);
