import {Select} from 'antd';
import React, {useEffect, useState} from 'react';
import useAllVehicleTypes from '../../hooks/useAllVehicleType';

function VehicleTypeSelect({onSelect, vehicleTypeFilter = () => true, ...props}) {
    const {allVehicleTypes, allVehicleTypesArray} = useAllVehicleTypes()
    const [vehicleTypeOpts, setVehicleTypeOpts] = useState([]);
    const [vehicleTypeId, setVehicleTypeId] = useState(props.value);

    useEffect(() => {
        setVehicleTypeOpts(allVehicleTypesArray
            .filter(vehicleTypeFilter)
            .map(vehicleType => ({
                value: vehicleType.vehicleTypeId,
                label: vehicleType.vehicleTypeName
            })));
    }, [allVehicleTypesArray, setVehicleTypeOpts]);

    return <Select value={vehicleTypeId} placeholder="Select vehicle type"
                   style={{width: '100%'}}
                   onChange={vehicleTypeId => {
                       setVehicleTypeId(vehicleTypeId);
                       onSelect(vehicleTypeId, allVehicleTypes[vehicleTypeId]);
                   }}
                   options={vehicleTypeOpts} {...props}/>;
}

export default React.memo(VehicleTypeSelect);