import {cloneDeep} from 'lodash';

export const VEHICLE_FUEL_TYPES = {
    petrol: 'Petrol', diesel: 'Diesel', lpg: 'Lpg', electric: 'Electric', hybrid: 'Hybrid'
};
export const DEFAULT_VEHICLE_RATE = 3;

export class VehicleType {

    static from(vehicleType) {
        return new VehicleType(cloneDeep(vehicleType));
    }

    constructor(data) {
        this.vehicleTypeId = '_';
        this.vehicleTypeName = null;
        this.rate = DEFAULT_VEHICLE_RATE;
        this.capacity = 10;
        this.fuelType = 'petrol';
        this.tankage = 100;
        this.maxDistance = 500;
        Object.assign(this, data);
    }

    clone() {
        return VehicleType.from(this);
    }

    getRate() {
        return Number.isFinite(this.rate) && this.rate >= 0 ? this.rate : DEFAULT_VEHICLE_RATE;
    }
}
