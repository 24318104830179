import React, {useEffect, useState} from 'react';
import LoaderButton from '../../components/LoaderButton';
import {ReactComponent as Copy} from '../../assets/icons/Copy.svg';
import {ReactComponent as Trash} from '../../assets/icons/Trash.svg';
import {Charter} from '../../model/charter';
import {Link} from 'react-router-dom';
import {ListGroup} from 'react-bootstrap';
import {charterModelData} from '../../services/ModelService';
import {FilterTags} from '../../containers/FilterTag';
import {filterItems} from './Charters';
import {Popconfirm} from 'antd/lib';
import {ulid} from 'ulid';
import {Button} from 'antd';

const CharterList = ({charters, mergeMode}) => {
    const [data, setData] = useState([]);
    const [isDeleting, setIsDeleting] = useState({});

    useEffect(() => {
        if (charters && setData) {
            setData(
                charters.map((charter) => {
                    return new Charter({
                        ...charter,
                        key: charter.charterId,
                        // warnings: charter.validateCharter(charters),
                    });
                })
            );
        }
    }, [charters, setData]);

    return (
        <ListGroup>
            <div className="row list-group-header">
                <div className="col-lg-10">Charters</div>
                <div className="col-lg-1 text-center">Status</div>
                <div className="col-lg-1 text-center">Actions</div>
            </div>
            {data.map((charter) => {
                return (
                    <Link key={charter.charterId} to={`/charters/${charter.charterId}`} style={{cursor: 'pointer'}}>
                        <ListGroup.Item>
                            <div className="row">
                                <div className="col-lg-6">{charter.name}</div>
                                <div className="col-lg-4">{charter.requestedBy}</div>
                                <div className="col-lg-1 d-flex justify-content-center simple-tags">
                                    <FilterTags filterItems={filterItems} model={charter}/>
                                </div>
                                <div className="col-lg-1 d-flex justify-content-around">
                                    <LoaderButton
                                        className="btn-icon-control"
                                        size="sm"
                                        // isLoading={isCloning}
                                        onClick={() => {
                                            console.log('Copy charter', charter.charterId);
                                            charter = charter.clone();
                                            charter.charterId = ulid();
                                            charter.name += '-COPY';
                                            charterModelData.save(charter).then(() => {
                                                console.log('Charter saved.');
                                            });
                                        }}
                                    >
                                        <Copy/>
                                    </LoaderButton>
                                    <Popconfirm title={`Are you sure you want to delete ${charter.name}?`}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                onCancel={e => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                onConfirm={e => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    setIsDeleting({...isDeleting, [charter.charterId]: true});
                                                    console.log('Delete charter', charter.charterId);
                                                    charterModelData.delete([charter.charterId, charter.itineraryId], true).then(() => {
                                                        console.log('Charter deleted.');
                                                        setIsDeleting({...isDeleting, [charter.charterId]: false});
                                                    });
                                                }}> <Button
                                        className="btn-icon-control btn-delete"
                                        size="sm"
                                        loading={isDeleting[charter.charterId]}
                                        icon={<Trash/>}/>
                                    </Popconfirm>
                                </div>
                            </div>
                        </ListGroup.Item>
                    </Link>
                );
            })}
        </ListGroup>
    );
};

export default React.memo(CharterList);
